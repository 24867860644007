<template>
  <div
    ref="viewNew"
    class="view-new"
    :class="{
      'view-new__more': isRenderDone && !isShowMoreBtn,
      'view-new__cloud-tag-sticky': usedBy === 'store', // 店铺页固定标签云
      'view-new__cloud-tag-sticky_no-tabs': usedBy === 'store' && isStoreNoTabs,
      'view-new__hide-overflow': !isRenderDone,
    }"
  >
    <div
      ref="viewNewContainer"
      class="view-new__container"
    >
      <!-- 标签云 -->
      <CloudTags
        ref="CloudTags"
        :is-view-new="true"
        :request-loading="requestLoading"
        @select-cloud-tag="$emit('selectCloudTag', $event)"
      >
        <template #prefix>
          <!-- sortbar -->
          <li>
            <slot></slot>
          </li>
        </template>

        <template
          v-if="isShowMoreBtn"
          #suffix
        >
          <!-- more -->
          <div
            class="view-new__btn"
            @click="onClick"
          >
            {{ language.SHEIN_KEY_PC_28056 }}
            <Icon
              name="sui_icon_more_down_14px_1"
              size="14px"
            />
          </div>
        </template>
      </CloudTags>
    </div>
  </div>
</template>

<script>
import CloudTags from './filter/CloudTags'
import { mapGetters, mapState } from 'vuex'
import { Icon } from '@shein-aidc/icon-vue3'
import { debounce } from '@shein/common-function'

export default {
  name: 'ViewNewCloudTag',
  components: {
    Icon,
    CloudTags,
  },
  props: {
    usedBy: {
      type: String,
      default: 'productList',
    },
    requestLoading: {
      type: Boolean,
      default: false
    }
  },
  data () { 
    return {
      isRenderDone: false,
      isClickedShowMore: false,
      isTagsMoreThanOneLine: false,
      isStoreNoTabs: true,
    }
  },
  computed: {
    ...mapGetters([
      'cloudTags',
    ]),
    ...mapState(['language']),
    isShowMoreBtn() {
      // 没有点击展开，且标签云超出一行
      if (typeof window === 'undefined') return false // SSR 直出时隐藏
      return this.isTagsMoreThanOneLine && !this.isClickedShowMore // 确定有多行、且未点击展开
    },
  },
  watch: {
    async cloudTags () {
      if (this.isClickedShowMore === true) return // 用户手动展开后不再自动收起
      
      await this.setTagVisible()
    }
  },
  emits: ['selectCloudTag'],
  mounted () {
    this.initBtnStatus()
    this.isStoreNoTabs = document.querySelector('.store-tab-header--container') === null
    window.addEventListener('resize', this.onScreenResize)
  },
  beforeUnmount () {
    window.removeEventListener('resize', this.onScreenResize)
  },
  methods: {
    toggleShow(value) {
      this.$el.style.visibility = value ? 'visible' : 'hidden'
    },
    async hideForReset () {
      this.isRenderDone = false
      await this.$nextTick()
      this.getAllTagItems().forEach((dom) => {
        dom.style.display = '' // 但保证各标签云 DOM 元素正常在文档流中计算与顶部的距离
      })
    },
    onScreenResize: debounce({
      func: async function() {
        this.hideForReset()
        this.setTagVisible()
        this.isRenderDone = true
      },
      wait: 50,
      options: {
        leading: true,
      }
    }),
    async initBtnStatus () {
      await this.setTagVisible()
      this.isRenderDone = true
    },
    onClick () { 
      this.isClickedShowMore = true
      this.setTagVisible()
    },
    getAllTagItems () {
      return Array.from(this.$el.querySelectorAll('.cloud-tags__items>li') || [])
    },
    getElBoundingClientRectTop () {
      let top = this.$el.getBoundingClientRect().top
      return (
        this.usedBy === 'store'
          ? top + 20 // 20 为标签云 padding top
          : top
      )
    },
    async setTagVisible () {
      if (!this.$el || typeof window === 'undefined') return

      await this.$nextTick()
      
      let hasElementHidden = false

      const childs = this.getAllTagItems();
      [...childs].forEach((dom) => {
        let isVisible = true // 默认可见

        // 未展开情况下，超出高度的标签隐藏
        const thisTagRectTop = Math.round(dom.getBoundingClientRect().top)
        const wrapperRectTop = Math.round(this.getElBoundingClientRectTop())
        if (
          !this.isClickedShowMore
          && thisTagRectTop !== wrapperRectTop
        ) {
          isVisible = false
          hasElementHidden = true
        }
        dom.style.display = isVisible ? '' : 'none'
      })

      this.isTagsMoreThanOneLine = hasElementHidden

      await this.$nextTick()
    },
    updateCloudTagValue(value) {
      this.$refs.CloudTags.updateValue(value)
    }
  }
}
</script>

<style lang="less">
@itemHeight: 36px;

.view-new__cloud-tag-sticky {
  position: sticky;
  top: 63px;
  z-index: 11;
  background-color: #fff;

  &_no-tabs {
    top: 0;
  }
}

.view-new {
  height: @itemHeight + 12px;
  margin-bottom: 0;
  transition: all .3s;

  &__container {
    display: inline-block;
    position: relative;
    padding-right: 50px;

    li {
      float: left;
      display: inline-block;
    }

    .sort-bar {
      padding: 0;
      height: @itemHeight;
      margin-right: 24px;
      margin-bottom: 12px;
    }

    .sort-bar__inner,
    .sort-bar__dropdown,
    .sort-bar .sui-select,
    .sort-bar .sui-select__trigger {
      width: 217px;
      height: @itemHeight;
    }

    .sui-select {
      .sui-input__prefix-inner {
        padding: 0;
      }

      .sort-bar__title {
        padding-left: 0;
      }

      .sui-input__inner-prefix {
        height: @itemHeight;
        font-weight: bold;
      }

      .sui-select-option {
        width: 100%;
      }

      .sui-select-option__icon {
        float: right;
      }
    }

    .sort-bar__inner, 
    .sort-bar__dropdown {
      z-index: 10;
    }

    .view-new__btn {
      position: absolute;
      top: 0;
      display: inline-flex;
      align-items: center;
      flex-wrap: nowrap;
      cursor: pointer;
      height: @itemHeight;
      vertical-align: middle;
      line-height: @itemHeight;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .cloud-tags__items {
      display: inline-block;
    }
  }

  &__more {
    /* stylelint-disable-next-line declaration-no-important */
    height: auto !important;
    display: flex;

    .view-new__btn {
      display: none;
    }

    .cloud-tags__items {
      height: auto;
    }
  }

  &__hide-overflow {
    overflow: hidden;
  }
}

.product-list-v2 {

  .list-skeleton__item{
    z-index: 1;
    background: #fff;
  }

  .product-list, .S-product-item {
    background: #fff;
  }

  .view-new .cloud-tags {
    margin-top: 0;
    display: flex;
  }
}
</style>
